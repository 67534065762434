@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css");
.form-group {
  margin: 1rem;
  padding: 0.5rem 0.9rem;
  box-shadow: 0 1px 6px 0 rgb(32 33 36 / 28%);
  display: flex;
  text-align: right;
  justify-content: flex-start;
  align-items: center;
  color: #101010;
  width: 300px;
  border-radius: 10px;
  background-color: #f4f4f4;
}

.form-group:focus-within {
  box-shadow: 0 1px 6px 0 gold;
}

body {
  background-image: url("https://images.newscientist.com/wp-content/uploads/2020/02/11165812/c0481846-wuhan_novel_coronavirus_illustration-spl.jpg");
}

.left {
  background-image: url("/src/images/covid.png");
  background-size: fill;
  background-position: center;
  height: 150px;
}

.navbar {
  background-color: white;
  padding: 10px;
}

.icon {
  font-size: 4rem;
  text-align: right;
  position: absolute;
  right: 0;
}
.app {
  padding-top: 3.7rem;
}
header {
  height: 10vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
  padding: 1rem;
  color: #f4f4f4;
  z-index: 9;
}

.educational {
  position: relative;
  padding: 1rem;
  display: flex;
  justify-content: center;

  align-items: center;

  min-width: 90%;
  color: black;
  flex-wrap: wrap;
}

/* .educational span {
  position: absolute;
  top: 0;
} */

.educational h3 {
  color: goldenrod;
}
.educational > div {
  padding: 3rem 2rem;
  box-shadow: 0 1px 6px 0 rgb(32 33 36 / 28%);
  border-radius: 30px;
  width: 100%;
  margin-bottom: 10px;
  color: black;
  background-color: white;
}

.educational a {
  width: 100%;
}

.numbers h1 {
  color: goldenrod;
}

.numbers > div {
  box-shadow: 0 1px 6px 0 rgb(32 33 36 / 28%);
  padding: 2rem 4rem;
  margin: 1rem;
}
.educational .icon-right {
  font-size: 3rem;
  position: absolute;
  left: 2rem;
  top: 0;
}

header .navbar {
  width: 100%;
}

body {
  overflow-x: hidden;
}

.bi-exclamation {
  font-size: 5rem;
  color: red;
}

.infoWrapper {
  box-shadow: 0 1px 6px 0 rgb(32 33 36 / 28%);
  margin: 10px;
  padding: 3rem 1rem;
  background-color: #ffffee;
  border-radius: 10px;
  font-size: 1.6rem;
}

.golden h1 {
  background-color: goldenrod;
  border-radius: 5px;
  padding: 0.7rem;
  color: white;
}

.heading {
  font-weight: bold;
}

.heading:nth-child(1) {
  color: goldenrod;
}

.main-heading {
  color: #f4f4f4;
}

footer {
  text-align: center;
}

a {
  color: inherit;
}

a:hover {
  text-decoration: none;
  color: inherit;
}

.resources {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.assessment {
  padding: 2rem 1rem;
  background-color: #ffffee;
  min-height: 100vh;
}

.assessment img {
  height: 50vh;
}
.assessment h1 {
  text-align: center;
}

.numbers ul {
  list-style: none;
}

.assessment .btn {
  border-radius: 50px;
  padding: 1rem;
}
@media screen and (max-width: 600px) {
  .golden h1 {
    font-size: 1.7rem;
  }
}
