.wrapper {
  position: relative;
  display: grid;
  background-color: #101010ee;
  color: #f4f4f4;
  min-height: 100vh;
  place-items: center;
  padding-bottom: 5rem;
}

.buttons {
  position: absolute;
  display: flex;
  justify-content: center;
  left: 0;
  width: 100%;
  bottom: 2rem;
}

.buttons .btn-warning {
  border-radius: 50%;
  width: 70px;
  height: 70px;
}
